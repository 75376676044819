// utils.js
export const formatDate = (dateString) => {
  if (!dateString) {
    return 'N/A'; 
  }
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'Invalid Date'; 
  }
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const compareDates = (dateString1, dateString2) => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);
  if (date1 < date2) {
    return 1; //'Date 1 is earlier than Date 2';
  } else if (date1 > date2) {
    return -1; //'Date 1 is later than Date 2';
  } else {
    return 0 ;// equal;
  }
};

export const GetDatesBetween = (startDateString, endDateString) => {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);
  const datesList = [];

  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    datesList.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return datesList;
};

  
  
  