import React, { useState, useEffect } from "react";
import {useTranslation } from "react-i18next";
import {utils, writeFile } from "xlsx";
import {Container,Row,Col,Button,Spinner,Tab,Nav,Badge,Modal} from "react-bootstrap";
import Card from "../../../components/Card";
import {useHistory} from "react-router-dom";
import {formatDate } from "../../../utils/date_utils";
import {restructuredAttendanceData} from "../../../utils/attendance_time_utils";
import {useQuery, useMutation, useQueryClient } from "react-query";
import {get_labor_processed_attendance,acceptOrRejectedAtendance,ApproveLaborAttendancePayment,RejectLaborAttendance,SetLaborPaymentPaid } from "../../../services/labor_attendanceApi";
import {get_project_data } from "../../../services/projectApi";
import {toast} from "react-toastify";
const AttendancePaymentSheetPreparation = () => {
 const { t } = useTranslation();
  const history = useHistory();
  const [selectAllSubmittedAttendance, setSelectAllSubmittedAttendance] = useState(false);
  const [selectedSubmittedAttendance, setSelectedSubmittedAttendance] = useState([]);
  const [selectAllPayment, setSelectAllPayment] = useState(false);
  const [selectedActivePayment, setSelectedActivePayment] = useState([]);
  const [currentAction, setCurrentAction] = useState();
  const [selectedPrepared, setSelectedPrepared] = useState([]);
  const [selectAllLabor, setselectAllLabor] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedActionOption, setSelectedActionOption] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const queryClient = useQueryClient();
  const acceptOrRejectedAtendanceMutation = useMutation(async (newData) => {
    await acceptOrRejectedAtendance(newData);
    queryClient.invalidateQueries("submitted_labor_attendance");
  });

  const ApproveAttendancePaymentMutation = useMutation(async (newData) => {
    await ApproveLaborAttendancePayment(newData);
    queryClient.invalidateQueries("submitted_labor_attendance");
  });
  
  const RejectAttendancePaymentMutation = useMutation(async (newData) => {
    await RejectLaborAttendance(newData);
    queryClient.invalidateQueries("submitted_labor_attendance");
  });
  
  const SetLaborPaymentPaidMutation = useMutation(async (PaymentStatus) => {
    await SetLaborPaymentPaid(PaymentStatus);
    queryClient.invalidateQueries("submitted_labor_attendance");
    setSelectedActivePayment([]);
  });
  
  const [projectData, setProjectData] = useState(null);
  const [selectedProject, setSelectedProject] = useState();
  const [selectedProjectName, setSelectedProjectName] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedActionOption("");
  };

  const getProjectQuery = useQuery(["projectdata"],() => get_project_data());
   useEffect(() => {
    if (!getProjectQuery.data) {
      // Data is still loading, don't proceed
      return;
    }
    if (getProjectQuery.data.length > 0) {
      setSelectedProject(getProjectQuery.data[0].id);
      setSelectedProjectName(getProjectQuery.data[0].name);
    }
   setProjectData(getProjectQuery.data);
  }, [getProjectQuery.data]);

  const get_processed_attendance = useQuery(
    ["submitted_labor_attendance", selectedProject],() => get_labor_processed_attendance(selectedProject));


  if (getProjectQuery.isLoading||get_processed_attendance.isLoading) {
    return <div>Loading...</div>;
  }
  if (getProjectQuery.isError) {
    return <div>Error: {getProjectQuery.error.message}</div>;
  }
  if (get_processed_attendance.isError) {
    return <div>Error: {get_processed_attendance.error.message}</div>;
  }
 

  const submittedData = get_processed_attendance.data.submitted;
  const preparedData =get_processed_attendance.data.prepared;
  const rejectedData = get_processed_attendance.data.rejected;
  const activePaymentData=get_processed_attendance.data.activePayments;

  const acceptOrRejectSubmittedAttendance = async (current_status,status, successMessage) => {
    if (selectedSubmittedAttendance.length === 0) {
      toast.error("Please select at least one attendance record", {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    const submitted_attendance = {
      projectId: selectedProject,
      selectedSubmittedAttendance,
      current_status: current_status,
      status:status
    };
    try {
      setIsSubmitting(true);
      await acceptOrRejectedAtendanceMutation.mutateAsync(submitted_attendance);
      toast.success(successMessage, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setSelectedSubmittedAttendance([]);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmittedAttendanceCheckboxChange = (event, SelectedDate) => {
    const selectedId = SelectedDate.date;
    setSelectedSubmittedAttendance((prevSelectedOrders) => {
      return event.target.checked
        ? [...prevSelectedOrders, SelectedDate]
        : prevSelectedOrders.filter((item) => item.date !== selectedId);
    });
  };
  
  const handleSubmittedAttendanceHeaderCheckboxChange = () => {
    setSelectAllSubmittedAttendance((prevSelectAll) => !prevSelectAll);
    setSelectedSubmittedAttendance((prevSelectedOrders) => {
      return selectAllSubmittedAttendance ? [] : [...submittedData];
    });
  };
  
   const handlePreparedHeaderCheckboxChange = () => {
    setselectAllLabor((prevSelectAll) => !prevSelectAll);
    setSelectedPrepared((prevSelectedOrders) => {
      return selectAllLabor ? [] : preparedData.map((item) => item);
    });
  };
  const handlePreparedCheckboxChange = (PreparedData) => {
    setSelectedPrepared((prevSelectedOrders) => {
      const preparedId = PreparedData.labor_id;
      return prevSelectedOrders.some((item) => item.labor_id === preparedId)
        ? prevSelectedOrders.filter((olderItem) => olderItem.labor_id !== preparedId)
        : [...prevSelectedOrders, PreparedData];
    });
  };

  const handleActivePaymentHeaderCheckboxChange = () => {
    setSelectAllPayment((prevSelectAll) => !prevSelectAll);
    setSelectedActivePayment((prevSelectedOrders) => {
      return !selectAllPayment ? activePaymentData.map((item) => item.id) : [];
    });
  };
  
  const handleActivePaymentCheckboxChange = (event, paymentData) => {
    const selectedId = paymentData.id;
    setSelectedActivePayment((prevSelectedOrders) => {
      return event.target.checked
        ? [...prevSelectedOrders, selectedId]
        : prevSelectedOrders.filter((itemId) => itemId !== selectedId);
    });
  };
  
  const handeleProjectChange = (projectId, projectName) => {
    setSelectedProject(projectId);
    setSelectedProjectName(projectName);
  };

  const handeleSubmittedAttendanceOptionChange = (action) => {
    if (selectedSubmittedAttendance.length === 0) {
      toast.error("Please select at least one attendance record", {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    setSelectedActionOption(action);
    setCurrentAction("prepare");
    setShow(true);
    if (action === "prepare") {
      setConfirmationMessage(
        <p> Are you sure you want to prepare the attendance payment? </p>
      );
    } else {
      setConfirmationMessage(
        <p className="text-danger">
          Are you sure you want to reject the attendance payment preparation?{" "}
        </p>
      );
    }
  };

  const handleSubmittedAttendanceAction = () => {
    setCurrentAction("prepare");
    if (selectedActionOption === "prepare") {
      const successmessage = "Attendance payment prepared successfully";
      acceptOrRejectSubmittedAttendance("prepared",0, successmessage);
      handleClose();
    } else if (selectedActionOption === "reject") {
      const successmessage = "Attendance preparation rejected";
      acceptOrRejectSubmittedAttendance("rejected",1, successmessage);
      handleClose();
    }
  };

  const ApprovePayment = async (paymentData,successMessage) => {
     setIsSubmitting(true);
     try {
      await ApproveAttendancePaymentMutation.mutateAsync(paymentData);
      toast.success(successMessage, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } 
    finally {
      setIsSubmitting(false);
    }
  };
  const handelApproveAttendancePayment = (action) => {
    setCurrentAction("approve");
    if (selectedPrepared.length === 0) {
        toast.error("Please select at least one attendance record that you want to approve payment", {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    setSelectedActionOption(action);
     setShow(true);
    if (action === "approve") {
       setConfirmationMessage(
        <p> Are you sure you want to approve the attendance payment? </p>
      );
    } else if(action ==="rejectapproval") {
       setConfirmationMessage(
        <p className="text-danger">
            Are you sure you want to reject the attendance payment approval?
        </p>
      );
    }
  };

  const handelePaymentApproval=async()=>{
   const paymentData= restructuredAttendanceData(selectedPrepared);
   if(selectedActionOption==="approve"){
        const successMessage="You Approve successfully";
       await ApprovePayment(paymentData,successMessage);
       setSelectedPrepared([]);
        handleClose();
      } 
else if(selectedActionOption==="rejectapproval"){
  await RejectAttendancePaymentMutation.mutateAsync(selectedPrepared);
  setSelectedPrepared([]);
  handleClose();
  toast.success("Labor attedndance send to repreparation", {
    position: "top-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
  }
  }

const setPymentStatusPaid=async()=>{
  if(selectedActivePayment.length===0){
    toast.error("Please select at least one worker from the list", {
      position: "top-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    return;
  }
 const PaymentStatus={
      ids:selectedActivePayment,
      is_closed:true,
      status:"paid",
      payed_by:"Melkamu"
    }
await SetLaborPaymentPaidMutation.mutateAsync(PaymentStatus);
 toast.success("The payment status is registered successfully", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
  }

 const exportActivePayments=() => {
  const restructuredData = activePaymentData.map((record) => {
    const {project_id,to_date, totalPayment, total_afternoon, total_holiday,
      total_morning,
      total_overtime,
      labor_id,
      morningPayment,
      number_of_days,
      overtimePayment,
      afternoonPayment,
      from_date,
      holidayPayment,
    } = record;
   const {first_name,father_name} = record.labor;
   const convertedToDate = new Date(to_date).toLocaleDateString();
   const convertedFromDate = new Date(from_date).toLocaleDateString();
    return {
      first_name,
      father_name,
      from_date:convertedFromDate,
      to_date:convertedToDate,
      number_of_days,
      total_morning,
      morningPayment,
      total_afternoon,
      afternoonPayment,
      total_overtime,
      overtimePayment,
      total_holiday,
      holidayPayment,
      totalPayment,
      labor_id,
      project_id,
    };
  });
 const worksheet = utils.json_to_sheet(restructuredData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet 1");
  writeFile(workbook, "labor_payment.xlsx");
  };

const ViewRejectedAttendanceDetail = (rejectedAttendance) => {
   const data=rejectedAttendance.attendance_ids;
   history.push('/rejected-labor-attendance-list', {data});
  }
  const printPaymentList = () => {
     if(activePaymentData.length===0){
      return;
     }
    const restructuredData = activePaymentData.map((record) => {
      const {
        to_date,
        totalPayment,
        morningPayment,
        number_of_days,
        overtimePayment,
        afternoonPayment,
        from_date,
        holidayPayment,
      } = record;
      const { first_name, father_name } = record.labor;
      const convertedToDate = new Date(to_date).toLocaleDateString();
      const convertedFromDate = new Date(from_date).toLocaleDateString();
      return {
        Name: first_name + ' ' + father_name,
        From: convertedFromDate,
        To: convertedToDate,
        Day: number_of_days,
        Payment: (afternoonPayment + morningPayment),
        OverTime: overtimePayment, 
        Holiday: holidayPayment, 
        Total: totalPayment, 
        Sign: ""
      };
    });
  
    const data = restructuredData;
    // Create a string variable to hold the HTML content of the table
    let tableContent = `
      <table>
        <thead>
          <tr>
            <th>#</th>${Object.keys(data[0]).map((key) => `<th>${key}</th>`).join('')}
          </tr>
        </thead>
        <tbody>
          ${data.map((item, index) => `
            <tr>
              <td>${index + 1}</td>${Object.values(item).map((value) => `<td>${value}</td>`).join('')}
            </tr>
          `).join('')}
        </tbody>
      </table>
    `;
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              margin: 1% 3%;
            }
            table {
              border-collapse: collapse;
              width: 100%;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            .header {
              text-align: center;
              font-weight: bold;
              margin-bottom: 20px;
            }
            .footer {
              margin-top: 20px;
            }
            .top-right {
              position: absolute;
              top: 0;
              right: 5%;
            }
          </style>
        </head>
        <body>
          <div class="top-right">
            <p>Printed Date: ${formattedDate}</p>
          </div>
          <div class="header">
            <h4> B Square Y Engineering  </h4>
            <h3> ቢ ስኴር ዋይ ኢንጂነሪንግ</h3>
            <h4>${selectedProjectName} project Labor Attendance Payment Card</h4>
          </div>
          ${tableContent}
          <div class="footer">
            <p>Time Keeper ________________   Prepared BY__________________ Checked BY________________</p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };
  return (
    <Container fluid>
      <div className="d-flex justify-content-between align-items-center p-3">
        <h6 className="font-weight-bold"> {selectedProjectName} project {t("labor_attendance")} </h6>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <select
            className="form-select form-control-sm m-2"
            onChange={(e) =>
              handeleProjectChange(
                e.target.value,
                e.target.options[e.target.selectedIndex].text
              )
            }
            value={selectedProject ? selectedProject : ''}
          >
            <option value="" hidden disabled>
              Select project
            </option>
            {projectData &&
              projectData.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <Row>
        <Col lg="12">
          <Tab.Container defaultActiveKey="submitted-tab">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="submitted-tab">
                  Submitted Attendance
                  <Badge variant="success ml-2"> {submittedData.length}</Badge>
                </Nav.Link>
              </Nav.Item>
             
              <Nav.Item>
                <Nav.Link eventKey="check-out-payment-tab">
                  Check out Payment
                  <Badge variant="success ml-2">{preparedData.length}</Badge>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="rejected-tab">
                  Rejected Payment
                  <Badge variant="danger ml-2">{rejectedData.length}</Badge>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="approved-tab">
                   Active Payment
                  <Badge variant="success ml-2">{activePaymentData.length}</Badge>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="submitted-tab">
                <Row>
                  <Col lg="12">
                    <Card className="card-block card-stretch">
                      <Card.Body className="p-0">
                        <div className="d-flex justify-content-between align-items-center p-3">
                          <h6 className="font-weight-bold">
                            {t("select_submitted_attendance_prpare_payment")}
                          </h6>
                          <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <select
                              className="form-select form-control-sm m-2"
                              value={selectedActionOption}
                              onChange={(e) =>
                                handeleSubmittedAttendanceOptionChange(e.target.value)
                              }
                              disabled={isSubmitting}
                            >
                              <option value="" disabled selected>
                                Select action
                              </option>
                              <option value="prepare">
                                {t("prepare_payment")}
                              </option>
                              <option value="reject">Reject</option>
                            </select>
                            {isSubmitting ? (
                              <Button variant="btn btn-info btn-sm m-2" size="sm">
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <span className="visually-hidden">
                                  Sending...
                                </span>
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table data-table mb-0" size="sm">
                            <thead className="table-color-heading">
                              <tr className="text-light">
                                <th scope="col" className="w-01 pr-0">
                                  <div className="d-flex justify-content-start align-items-end mb-1">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input m-0"
                                        id="customCheck"
                                        checked={selectAllSubmittedAttendance}
                                        onChange={handleSubmittedAttendanceHeaderCheckboxChange}
                                        />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="customCheck"
                                      />
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <label className="text-muted mb-0">
                                    {t("date")}
                                  </label>
                                </th>
                                <th>
                                  <label className="text-muted mb-0">
                                 
                                    {t("labor")}
                                  </label>
                                </th>
                                <th>
                                  <label className="text-muted mb-0">
                                
                                    {t("morning")}
                                  </label>
                                </th>
                                <th>
                                  <label className="text-muted mb-0">
                                  
                                    {t("afternoon")}
                                  </label>
                                </th>
                                <th>
                                  <label className="text-muted mb-0">
                                 
                                    {t("over_time")}
                                  </label>
                                </th>
                                <th>
                                  <label className="text-muted mb-0">
                                   
                                    {t("holiyday")}
                                  </label>
                                </th>
                                <th>
                                  <label className="text-muted mb-0">
                                  {t("total")}
                                  </label>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {submittedData.map((item) => (
                                <tr
                                  key={item.date}
                                  className="white-space-no-wrap"
                               >
                                  <td className="pr-0">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                    <input
                               type="checkbox"
                               className="custom-control-input m-0"
                                id={`customCheck_${item.date}`}
                                  checked={selectedSubmittedAttendance.includes(item)}
                                    onChange={(event) => handleSubmittedAttendanceCheckboxChange(event, item)}
                                       />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={`customCheck_${item.date}`}
                                      ></label>
                                    </div>
                                  </td>
                                  <td>{formatDate(item.date)}</td>
                                  <td> {item.totalrecords}</td>
                                  <td>{item.totalmornings.toFixed(2)}</td>
                                  <td>{item.totalafternoons.toFixed(2)}</td>
                                  <td>{item.totalover_times.toFixed(2)}</td>
                                  <td>{item.totalholidays.toFixed(2)}</td>
                                  <td>{item.grandtotal.toFixed(2)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="rejected-tab">
                <div className="table-responsive">
                  <table className="table data-table mb-0" size="sm">
                    <thead className="table-color-heading" >
                      <tr className="text-light">
                        <th scope="col" className="w-01 pr-0">#</th>
                        <th>
                          <label className="text-muted mb-0">
                            {t("full_name")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                          
                            {t("phone")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                        
                          {t("number_of_days")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                            
                            {t("working_payment")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                          
                            {t("over_time")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                          
                            {t("over_time_payment")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                         
                            {t("holiyday")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                         
                            {t("holiyday_payment")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                             {t("total_payment")}
                          </label>
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rejectedData.map((item,index) => (
                        <tr key={item.labor_id} className="white-space-no-wrap">
                          <td className="pr-0"> {index+1} </td>
                          <td>{item.labor.first_name} {item.labor.father_name} </td>
                          <td>{item.labor.phone}</td>
                          <td>{item.attendance_ids.length}</td>
                          <td>{item.dayaPayment.toFixed(2)}</td>
                          <td>{item.total_overtime.toFixed(2)}</td>
                          <td>{item.overtimePayment.toFixed(2)}</td>
                          <td>{item.total_holiday.toFixed(2)}</td>
                          <td>{item.holidayPayment}</td>
                          <td>{item.totalPayment.toFixed(2)}</td>
                          <td> <Button variant="btn btn-info btn-sm m-2" onClick={() => ViewRejectedAttendanceDetail(item)}>Detail</Button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="check-out-payment-tab">
              <div className="d-flex justify-content-between align-items-center p-3">
                      <h6 className="font-weight-bold">{t("labor_attendance")} List for approval </h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <select
                              className="form-select form-control-sm m-2"
                              value={selectedActionOption}
                              onChange={(e) =>
                                handelApproveAttendancePayment(e.target.value)
                              }
                              disabled={isSubmitting}
                            >
                              <option value="" disabled selected>
                                Select action
                              </option>
                              <option value="approve">
                                {t("approve")}
                              </option>
                              <option value="rejectapproval">Reject</option>
                            </select>
                            {isSubmitting ? (
                              <Button variant="btn btn-info btn-sm m-2" size="sm">
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                <span className="visually-hidden">
                                  Sending...
                                </span>
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                    </div>
                <div className="table-responsive">
                  <table className="table data-table mb-0" size="sm">
                    <thead className="table-color-heading" >
                      <tr className="text-light">
                        <th scope="col" className="w-01 pr-0">
                          <div className="d-flex justify-content-start align-items-end mb-1">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                type="checkbox"
                                className="custom-control-input m-0"
                                id="customCheck_header_prepared"
                                checked={selectAllLabor}
                                onChange={handlePreparedHeaderCheckboxChange}
                                key="header-customCheck-header-prepared"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck_header_prepared"
                              />
                            </div>
                          </div>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                            {t("full_name")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                            
                            {t("phone")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                           
                            {t("number_of_days")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                           
                            {t("working_payment")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                          
                            {t("over_time")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                           
                            {t("over_time_payment")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                         
                            {t("holiyday")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                        
                            {t("holiyday_payment")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                         
                            {t("total_payment")}
                          </label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {preparedData.map((item) => (
                        <tr
                          key={item.labor_id}
                          className="white-space-no-wrap"
                          
                        >
                          <td className="pr-0">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                type="checkbox"
                                className="custom-control-input m-0"
                                id={`prepared_customCheck_${item.labor_id}`}
                                checked={selectedPrepared.includes(
                                  item
                                )}
                                onChange={() => handlePreparedCheckboxChange(item)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`prepared_customCheck_${item.labor_id}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            {item.labor.first_name} {item.labor.father_name}
                          </td>
                          <td>{item.labor.phone}</td>
                          <td>{item.attendance_ids.length}</td>
                          <td>{item.dayaPayment.toFixed(2)}</td>
                          <td>{item.total_overtime.toFixed(2)}</td>
                          <td>{item.overtimePayment.toFixed(2)}</td>
                          <td>{item.total_holiday.toFixed(2)}</td>
                          <td>{item.holidayPayment}</td>
                          <td>{item.totalPayment.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="approved-tab">
              <div className="d-flex justify-content-between align-items-center p-3">
                <h6 className="font-weight-bold">Ative Payment List </h6>
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  <Button onClick={() => printPaymentList()} variant="btn btn-info btn-sm m-2" size="sm" > Print </Button>
                  <Button onClick={exportActivePayments} variant="btn btn-info btn-sm m-2" size="sm"> Export </Button>
                  <Button onClick={setPymentStatusPaid} variant="btn btn-success btn-sm m-2" size="sm">Set Paied</Button>
                </div>
              </div>
              <div className="table-responsive">
                  <table className="table data-table mb-0" size="sm">
                    <thead className="table-color-heading" >
                      <tr className="text-light">
                        <th scope="col" className="w-01 pr-0">
                          <div className="d-flex justify-content-start align-items-end mb-1">
                            <div className="custom-control custom-checkbox custom-control-inline">
                              <input
                                type="checkbox"
                                className="custom-control-input m-0"
                                id="customCheck_paid_header"
                                checked={selectAllPayment}
                                onChange={handleActivePaymentHeaderCheckboxChange}
                                key="customCheck_paid_header"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheck_paid_header"
                              />
                            </div>
                          </div>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                            {t("full_name")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                            {t("phone")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                            {t("date")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                            
                            {t("number_of_days")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                          
                            {t("overtime_payment")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                          
                            {t("holiday_payment")}
                          </label>
                        </th>
                        <th>
                          <label className="text-muted mb-0">
                         
                            {t("total_payment")}
                          </label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activePaymentData.map((item) => (
                        <tr key={item.id} className="white-space-no-wrap">
                          <td className="pr-0">
                          <div className="custom-control custom-checkbox custom-control-inline">
                            <input
                              type="checkbox"
                              className="custom-control-input m-0"
                              id={`customCheck_payment${item.id}`}
                              checked={selectedActivePayment.includes(item.id)}
                              onChange={(event) => handleActivePaymentCheckboxChange(event, item)}/>
                              <label className="custom-control-label" htmlFor={`customCheck_payment${item.id}`}> </label>
                          </div>
                         </td>
                          <td>{item.labor.first_name} {item.labor.father_name}</td>
                          <td>{item.labor.phone}</td>
                          <td>
                          <p className="m-0 text-secondary small"> From {formatDate(item.from_date)} </p>
                          <p className="m-0 text-secondary small">To  {formatDate(item.to_date)}</p>
                          </td>
                          <td>{item.number_of_days}</td>
                          <td>{item.overtimePayment.toFixed(2)}</td>
                          <td>{item.holidayPayment}</td>
                          <td>{item.totalPayment.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose} centered={true}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Confirm Your Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmationMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleClose}>
            No, cancel the action
          </Button>
          {currentAction==="approve" ?  (
          <Button variant="primary" onClick={handelePaymentApproval}
          disabled={isSubmitting}>
          {isSubmitting ? (
            <span>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Sending...</span>
            </span>
          ) : (
            t("Yes, I'm sure.")
          )}
          </Button>
          ):("")}
          {currentAction==="prepare" ?  (
          <Button variant="success" onClick={handleSubmittedAttendanceAction}
          disabled={isSubmitting}>
          {isSubmitting ? (
            <span>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">Sending...</span>
            </span>
          ) : (
            t("Yes, I'm sure.")
          )}
          </Button>
          ):("")}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AttendancePaymentSheetPreparation;
