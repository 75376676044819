import {fetchData,postData,updateData } from '../utils/apiUtils';
export const get_department= async () => {
  const responseData = await fetchData('employee/department');
    return responseData;
};


export const all_employee = async () => {
  const responseData = await fetchData('employee');
    return responseData;
  };

export const get_employee = async (id) => {
 const responseData = await fetchData(`employee/byid?id=${encodeURIComponent(id)}`);
  return responseData;
  };

  export const get_employeeDetail = async (emp_id) => {
    const responseData = await fetchData(`employee/employee_detail/byid?emp_id=${encodeURIComponent(emp_id)}`);
    return responseData;

  };
  
  export const RegisterEmployee = async (employeeData) => {
     const responseData = await postData('employee/register_employee', employeeData);
    return responseData;
  };

  export const updateTin = async (data) => {
    const responseData = await updateData('people/tin', data);
    return responseData;
  }

  // employee detail
  export const get_employeeSalary = async (step,grade) => {
    const responseData = await fetchData(`employee/scale/salary?step=${encodeURIComponent(step)}&grade=${encodeURIComponent(grade)}`);
    return responseData;
  };


  export const get_empDetail_dropDown_data = async () => {
   const responseData = await fetchData(`employee/employee_detail/support_data`);
    return responseData;
  };
  
  export const RegisterrEmpDetail = async (employeeData) => {
    const responseData = await postData('employee/employee_detail/register', employeeData);
    return responseData;
  };
  
  export const RegisterEmpEducation = async (empData) => {
    const responseData = await postData('/employee/employee_education/register', empData);
    return responseData;
  };
  
  export const RegisterEmpExperience = async (empData) => {
    const responseData = await postData('employee/employee_experience/register', empData);
    return responseData;
  };
  
  export const get_employeeReference = async (emp_id) => {
     const responseData = await fetchData(`employee/employee_reference/byempid?emp_id=${encodeURIComponent(emp_id)}`);
     return responseData;
  };


  export const RegisterEmpReference = async (empData) => {
    const responseData = await postData('employee/employee_reference/register', empData);
    return responseData;
  };
  
  // labor List
  export const get_labor_data = async () => {
    const responseData = await fetchData(`employee/labor`);
     return responseData;
    };

  export const RegisterLabor = async (empData) => {
    const responseData = await postData('employee/labor/register', empData);
    return responseData;
    };