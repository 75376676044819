import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {useLocation } from 'react-router-dom';
import { useMutation } from "react-query";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import { validateForm } from "../../../middleware/validateEempExperienceForm";
import {RegisterEmpExperience} from "../../../services/employeeApi";
import AlertModal from "../../sharedComponent/AlertModal";
const Employeeadd = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [formData, setFormData] = useState({

    emp_id:id,
    employer:"",
    work_place:"",
    position:"",
    Job_description: "",
    relevant_skills:"",
    achievements:"",
    from_date:"",
    to_date:"",
    updated_by:""
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAlert, setshowAlert] = useState();
  const handleClose = () => setshowAlert(false);
  const [AlertMessage, setAlertMessage] = useState("");
  const [alertTitle, setalertTitle] = useState("");
  const handleInputChange = (e, field, nestedField) => {
    const value = nestedField
      ? { ...formData[field], [nestedField]: e.target.value }
      : e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
    // Clear the validation error for the field when the value changes
    setValidationErrors({
      ...validationErrors,
      [field]: "",
    });
  };
  const registrationMutation = useMutation(RegisterEmpExperience);
  const handleRegistration = async (formData) => {
    try {
      setIsSubmitting(true);
      await registrationMutation.mutateAsync(formData);
      setalertTitle("Success");
      setAlertMessage("Registration successful");
    } catch (error) {
      setalertTitle("Error");
      setAlertMessage(error.message);
    } finally {
      setIsSubmitting(false);
      setshowAlert(true);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      handleRegistration(formData);
    } else {
      setValidationErrors(errors);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <AlertModal
              show={showAlert}
              onHide={handleClose}
              title={alertTitle}
              message={AlertMessage}
            />
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb p-0 mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/employee">{t("employee")}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`employee-view/?id=${id}`}>{t("employee_view")}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {t("add_employee_experience")}
                    </li>
                  </ol>
                </nav>
              </div>
              <Link
                to={`employee-view/?id=${id}`}
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-between"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2">{t("back")}</span>
              </Link>
            </div>
          </Col>
          <Col lg="12" className="mb-3 d-flex justify-content-between">
            <h4 className="font-weight-bold0 d-flex align-items-center">
              {t("add_new_employee_experience")}
            </h4>
          </Col>
          <Col lg="12">
            <Card>
              <Card.Body>
                <Row>
                  <Col md="12">
                    <Form
                      className="row g-3 date-icon-set-modal"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("employer")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter employer"
                          value={formData.employer}
                          onChange={(e) => handleInputChange(e, "employer")}
                        />
                        {validationErrors.employer && (
                          <div className="text-danger">
                            {validationErrors.employer}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("work_place")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter place of work"
                          value={formData.work_place}
                          onChange={(e) => handleInputChange(e, "work_place")}
                        />
                        {validationErrors.work_place && (
                          <div className="text-danger">
                            {validationErrors.work_place}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("position")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter position"
                          value={formData.position}
                          onChange={(e) => handleInputChange(e, "position")}
                        />
                        {validationErrors.position && (
                          <div className="text-danger">
                            {validationErrors.position}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text1"
                          className="font-weight-bold text-muted "
                        >
                          {t("Job_description")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Job_description"
                          value={formData.Job_description}
                          onChange={(e) => handleInputChange(e, "Job_description")}
                        />
                        {validationErrors.Job_description && (
                          <div className="text-danger">
                            {validationErrors.Job_description}
                          </div>
                        )}
                      </div>
                    
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("from_date")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter  date"
                          value={formData.from_date}
                          onChange={(e) => handleInputChange(e, "from_date")}
                        />
                        {validationErrors.from_date && (
                          <div className="text-danger">
                            {validationErrors.from_date}
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text4"
                          className="font-weight-bold text-muted "
                        >
                          {t("to_date")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter  date"
                          value={formData.to_date}
                          onChange={(e) => handleInputChange(e, "to_date")}
                        />
                        {validationErrors.to_date && (
                          <div className="text-danger">
                            {validationErrors.to_date}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("achievements")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter achievements in you experience"
                          value={formData.achievements}
                          onChange={(e) => handleInputChange(e, "achievements")}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <Form.Label
                          htmlFor="Text5"
                          className="font-weight-bold text-muted "
                        >
                          {t("relevant_skills")}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter relevant skills"
                          value={formData.relevant_skills}
                          onChange={(e) => handleInputChange(e, "relevant_skills")}
                        />
                      </div>
                    </Form>
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <span>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="visually-hidden">Sending...</span>
                          </span>
                        ) : (
                          t("add_employee_experience")
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Employeeadd;
